<template>
  <div id="app">
    <div class="particles"></div>
    <div class="wrapper">
      <div v-if="lable === 'jianjian'" id="jiajia" class="switch-button" @click="jianjian">穗岁</div>
      <div v-if="lable === 'Coisini'" id="jiajia" class="switch-button" @click="jianjian">Coisini</div>

      <div v-if="currentClass && allClasses.todayOrTomorrow !== '0'" class="class-info">
        <h2 class="animated-heading">正在上课：{{ currentClass.courseName }}</h2>
        <p>地点：{{ currentClass.location }}</p>
        <p>开始时间：{{ currentClass.beginning }}</p>
        <p>结束时间：{{ currentClass.ending }}</p>
        <h3 class="countdown">距离下课还有：{{ countdown }}</h3>
      </div>

      <div v-if="nextClass" class="class-info next-class">
        <p v-if="todayOrTomorrow === '明天'" class="no-more">小主，今天没有课程了噢！这是明天哒</p>
        <h2>下一节课：{{ nextClass.courseName }}</h2>
        <p>地点：{{ nextClass.location }}</p>
        <p>开始时间：{{ nextClass.beginning }}</p>
        <p>结束时间：{{ nextClass.ending }}</p>
        <h3>距离上课还有：{{ nextCountdown }}</h3>
      </div>

      <div v-else>
        <h2 v-if="noClass && !allClasses.length > 0" class="no-class-message">别期待啦，明天没有课噢~</h2>
        <h2 v-if="noClass && !allClasses.length > 0" class="no-class-message">要好好休息~</h2>
        <h2 v-if="allClasses.length > 0" class="h">小主，今天的课已经上完了噢</h2>
        <h2 v-if="allClasses.length > 0" class="h">明天的课将在</h2>
        <h2 v-if="allClasses.length > 0" class="h">一小时内</h2>
        <h2 v-if="allClasses.length > 0" class="h">刷新</h2>
        <h2 v-if="allClasses.length > 0" class="h">后端加载中…………</h2>
      </div>

      <div v-if="allClasses.length > 0" class="all-classes">
        <h2>{{ todayOrTomorrow }}的所有课程</h2>
        <details v-for="cls in allClasses" :key="cls.id" class="class-details">
          <summary class="class-summary">
            <span class="icon">{{ cls.type }}</span>
            {{ cls.courseName }} ({{ cls.beginning }} 到 {{ cls.ending }})
          </summary>
          <ul>
            <li>地点：{{ cls.location }}</li>
            <li>开始时间：{{ cls.beginning }}</li>
            <li>结束时间：{{ cls.ending }}</li>
          </ul>
        </details>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      currentClass: null,
      nextClass: null,
      countdown: '',
      nextCountdown: '',
      currentInterval: null,
      nextInterval: null,
      noClass: false,
      todayOrTomorrow: '',
      allClasses: [],
      lable: 'Coisini',
    };
  },
  methods: {
    async fetchClassData() {
      try {
        const response = await axios.get('/api/course/' + this.lable);
        const data = response.data.data;
        this.allClasses = data; // 保存所有课程到数组中
        this.allClasses.sort((a, b) => this.timeToMinutes(a.beginning) - this.timeToMinutes(b.beginning)); // 按开始时间排序

        if (data.length === 0) {
          this.noClass = true;
          return;
        }

        this.todayOrTomorrow = data[0].todayOrTomorrow;
        this.getClasses(data);
      } catch (error) {
        console.error('获取数据失败:', error);
      }
    },
    
    jianjian() {
      this.lable = this.lable === 'jianjian' ? 'Coisini' : 'jianjian';
      this.fetchClassData();
    },
    
    getClasses(classes) {
      const now = new Date();
      const currentTime = now.getHours() * 60 + now.getMinutes();

      let currentClass = null;
      let nextClass = null;
      let minTimeDiff = Infinity;

      classes.forEach(cls => {
        const startTime = this.timeToMinutes(cls.beginning);
        const endTime = this.timeToMinutes(cls.ending);

        if (cls.todayOrTomorrow === "今天") {
          // 正在上课
          if (currentTime >= startTime && currentTime < endTime) {
            currentClass = cls;
            this.startCountdown(cls, true);
          }

          // 找下一节课
          if (currentTime < startTime) {
            const timeDiff = startTime - currentTime;
            if (timeDiff < minTimeDiff) {
              minTimeDiff = timeDiff;
              nextClass = cls;
            }
          }
        } else if (cls.todayOrTomorrow === "明天") {
          const timeDiff = (1440 - currentTime) + startTime;
          if (timeDiff < minTimeDiff) {
            minTimeDiff = timeDiff;
            nextClass = cls;
          }
        }
      });

      this.currentClass = currentClass;
      this.nextClass = nextClass;

      // 没有课程
      if (!this.currentClass && !this.nextClass) {
        this.noClass = true;
      } else {
        this.noClass = false;
      }

      // 启动下一节课倒计时
      if (this.nextClass) {
        this.startCountdown(this.nextClass, false);
      } else {
        this.nextCountdown = '';
      }
    },

    timeToMinutes(time) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    },

    startCountdown(cls, isCurrentClass) {
      const now = new Date();
      let targetTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), ...cls.beginning.split(':').map(Number));
      const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), ...cls.ending.split(':').map(Number));

      if (now > targetTime) {
        targetTime.setDate(targetTime.getDate() + 1);
      }

      if (now > endTime) {
        endTime.setDate(endTime.getDate() + 1);
      }

      if (isCurrentClass) {
        if (this.currentInterval) clearInterval(this.currentInterval);
        this.currentInterval = this.createCountdown(endTime, true);
      } else {
        if (this.nextInterval) clearInterval(this.nextInterval);
        this.nextInterval = this.createCountdown(targetTime, false);
      }
    },

    createCountdown(targetTime, isCurrentClass) {
      return setInterval(() => {
        const now = new Date();
        const diff = targetTime - now;

        if (diff <= 0) {
          clearInterval(isCurrentClass ? this.currentInterval : this.nextInterval);
          if (isCurrentClass) this.countdown = '';
          else this.nextCountdown = '';
          return;
        }

        const hours = Math.floor(diff / 1000 / 60 / 60);
        const minutes = Math.floor((diff / 1000 / 60) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        if (isCurrentClass) {
          this.countdown = `${hours}时${minutes}分${seconds}秒`;
        } else {
          this.nextCountdown = `${hours}时${minutes}分${seconds}秒`;
        }
      }, 1000);
    },
  },
  created() {
    this.fetchClassData();
  },
};
</script>

<style>
#app {
  margin-top: 20px;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  color: white;
  text-align: center;
  font-family: 'Arial', sans-serif;
}
.h{
  color: aqua;  
}
.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://southern-city-guardian.oss-cn-beijing.aliyuncs.com/brackgroup.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
  animation: particleMove 30s linear infinite;
}

@keyframes particleMove {
  0% { background-position: 0 0; }
  100% { background-position: 100% 100%; }
}

.wrapper {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  max-height: calc(100vh - 40px); /* 适应屏幕高度 */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
  margin: 0 auto;
  overflow-y: auto; /* 允许垂直滚动 */
}

.switch-button {
  display: inline-block;
  background-color: transparent;
  color: white;
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.switch-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.class-info {
  margin-bottom: 20px;
}

.countdown,
.next-class .countdown {
  font-size: 1.2em;
  color: yellow;
}

.no-class-message {
  font-size: 1.5em;
  color: red;
}

.all-classes {
  margin-top: 20px;
}

.class-details {
  margin: 10px 0;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding: 10px;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: blue; /* 或根据课程类型使用不同的颜色 */
  border-radius: 50%;
  margin-right: 8px;
}
</style>
